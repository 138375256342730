import { getHumanReadableDate, getNoSlotsInRangeMessage } from '../../includes/date-utils';
import { PropTypes } from 'prop-types';

const EmptyEventItem = ( props ) => {
	const { date } = props;

	return (
		<li className="wc-bookings-availability-item">
			<div className="wc-bookings-availability-item-subheader">
				<span>{ getHumanReadableDate( date ) } </span>
			</div>
			<div className="wc-bookings-availability-item-body">
				<div className="wc-bookings-availability-item-empty">
					{ getNoSlotsInRangeMessage( 'today' ) }
				</div>
			</div>
		</li>
	);
};

export default EmptyEventItem;

EmptyEventItem.propTypes = {
	date: PropTypes.object,
};
