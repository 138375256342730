/**
 * External dependencies
 */
import PropTypes from 'prop-types';

/**
 * External dependencies.
 */
import { useState } from '@wordpress/element';
import { addQueryArgs } from '@wordpress/url';
import apiFetch from '@wordpress/api-fetch';
import { pickBy, identity, reduce } from 'lodash';

/**
 * Internal dependencies.
 */
import ProductsContext from './context';

/**
 * Helper function that transforms array of products returned from API
 * to a more convenient Object keyed by products ids.
 *
 * @param {array} products
 */
const transformProductsListToObject = ( products ) => {
	return reduce( products, function( obj, product ) {
		const id = product.id;
		delete product.id;
		product.durationUnit = product.duration_unit;
		delete product.duration_unit;
		obj[ id ] = product;
		return obj;
	}, {} );
};

const ProductsState = ( props ) => {
	const [ products, setProducts ] = useState( { products: {}, count: false, query: {} } );
	const [ productsRequesting, setProductsRequesting ] = useState( false );
	const [ productsError, setProductsError ] = useState( false );

	const requestProducts = ( query ) => {
		query = query || {};

		const {
			productIds = '',
		} = query;

		const queryArgs = {
			include: productIds,
			per_page: -1,
			_locale: 'user',
		};

		const path = addQueryArgs(
			'/wc-bookings/v1/products',
			pickBy( queryArgs, identity )
		);

		setProductsRequesting( true );
		apiFetch( { path } ).then(
			( _products ) => {
				const productsObj = transformProductsListToObject( _products );
				setProducts( { products: productsObj, count: _products.length, query } );
				setProductsError( false );
				setProductsRequesting( false );
			},
			( error ) => {
				setProductsError( error );
				setProductsRequesting( false );
			}
		);
	};

	const getProducts = () => {
		return products;
	};

	return (
		<ProductsContext.Provider value={ {
			products: products.products,
			getProducts,
			requestProducts,
			productsError,
			productsRequesting,
		} }
		>
			{ props.children }
		</ProductsContext.Provider>
	);
};

ProductsState.propTypes = {
	children: PropTypes.object,
	attributes: PropTypes.object,
	setAttributes: PropTypes.func,
	isFrontend: PropTypes.bool,
};

export default ProductsState;
