/**
 * External dependencies
 */
import { Icon } from '@wordpress/components';

export default () => (
	<Icon
		icon={
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
			>
				<path d="M22 12l-4-4v3H3v2h15v3z" />
				<path d="M0 0h24v24H0z" fill="none" />
			</svg>
		}
	/>
);
