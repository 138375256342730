/**
 * External dependencies
 */
import { useState } from '@wordpress/element';
import { PropTypes } from 'prop-types';
import { __ } from '@wordpress/i18n';
import apiFetch from '@wordpress/api-fetch';
import moment from 'moment';

/**
 * Internal dependencies
 */
import './style.scss';

const BookButton = ( props ) => {
	const [ loading, setLoading ] = useState( false );

	const onClickBookButton = () => {
		const { availability, date, hasPersons, hasResources, productId, permalink } = props;
		setLoading( true );

		if ( hasPersons || hasResources || ( 1 < availability.length ) ) {
			window.location.href = permalink;
		} else if ( 1 === availability.length ) {
			apiFetch( {
				// eslint-disable-next-line no-undef
				url: wc_bookings_availability_args.ajax_url + '=wc_bookings_add_booking_to_cart' + '&product_id=' + productId + '&date=' + encodeURIComponent( moment( date ).format( 'YYYY-MM-DDTHH:mm' ) ) + '&security=' + wc_bookings_availability_args.nonces.add_booking_to_cart,
			} )
				.then( ( data ) => {
					if ( data.booked ) {
						setLoading( false );
						// eslint-disable-next-line no-undef
						window.location.href = wc_bookings_availability_args.checkout_url;
					} else {
						window.location.href = data.link;
					}
				} );
		}
	};

	const onClickMoreDetails = () => {
		const { permalink, isPreview } = props;

		if ( isPreview ) {
			return false;
		}

		window.location.href = permalink;
	};

	const { availability, hasPersons, hasResources, permalink, durationType, isPreview } = props;

	let showDetails = false;
	let buttonText;
	const buttonClassName = 'wp-block-button__link wc-bookings-availability-book-button';

	if ( hasPersons || hasResources || 1 < availability.length || 'customer' === durationType ) {
		buttonText = __( 'Select options', 'woocommerce-bookings-availability' );
	} else if ( 1 === availability.length ) {
		buttonText = __( 'Book now', 'woocommerce-bookings-availability' );
		showDetails = true;
	}

	return ( <div className="wc-bookings-availability-book-button-container wp-block-button">
		{ showDetails && <a href={ permalink } className="wc-bookings-availability-book-button-more-details" onClick={ onClickMoreDetails }>{ __( 'More details', 'woocommerce-bookings-availability' ) } </a> }
		{ ( isPreview ) ? (
			<span className={ buttonClassName }>
				{ buttonText }
			</span>
		) : (
			<button onClick={ onClickBookButton } disabled={ loading } className={ buttonClassName }>
				{ buttonText }
			</button>
		) }
	</div> );
};

BookButton.propTypes = {
	/**
	 * Number of events available
	 */
	availability: PropTypes.array.isRequired,
	/**
	 * Date of the event
	 */
	date: PropTypes.string.isRequired,
	/**
	 * Whether or not this booking has persons
	 */
	hasPersons: PropTypes.bool,
	/**
	 * Whether or not this booking has resources
	 */
	hasResources: PropTypes.bool,
	/**
	 * Products permalink
	 */
	permalink: PropTypes.string.isRequired,
	/**
	 * Id of the product
	 */
	productId: PropTypes.string.isRequired,

	durationType: PropTypes.string,
	isPreview: PropTypes.bool,
};

export default BookButton;
