/**
 * External dependencies
 */
import { useContext } from '@wordpress/element';
import PropTypes from 'prop-types';
import { Popover, SlotFillProvider } from '@wordpress/components';

/**
 * Internal dependencies
 */
import EventCalendar from '.';
import '../../../js/blocks/bookings-schedule/style.scss';
import { IconTrendingFlat } from '../icons';
import { getReadableDateRangeString, getDateRangeTypeString } from '../../includes/date-utils';
import commonContext from '../../blocks/common-context/context';
import calendarContext from '../../blocks/bookings-calendar/context/context';
import AvailabilityState from '../../blocks/availability-context/availability-state';

const CalendarContainer = ( props ) => {
	const {
		offset,
		gotoCurrent,
		gotoNext,
		gotoPrev,
		showDateNav,
	} = useContext( commonContext );

	const {
		dateRangeType,
	} = useContext( calendarContext );

	const onDummyClick = ( e ) => {
		e.preventDefault();
	};

	const attributes = props;
	const dateRangeString = getReadableDateRangeString( dateRangeType, offset );
	const dateRangeTypeString = getDateRangeTypeString( dateRangeType );
	return (
		<SlotFillProvider>
			<div>
				<div className="wc-bookings-availability-header">
					<div className="wc-bookings-availability-date">
						{ dateRangeString }
					</div>
					{ ( true === showDateNav || 'true' === showDateNav ) ? (
						<div className="wc-bookings-availability-navigation">
							{ /* eslint-disable jsx-a11y/anchor-is-valid */ }
							{ ( offset <= 0 ) ? // disable past days/weeks/months.
								<a href="#" className="wc-bookings-availability-navigation-prev wc-bookings-availability-navigation-disabled" onClick={ onDummyClick }>
									{ <IconTrendingFlat /> }
								</a> :
								<a href="#" className="wc-bookings-availability-navigation-prev" onClick={ gotoPrev }> { <IconTrendingFlat /> } </a>
							}
							<a href="#" className="wc-bookings-availability-navigation-current" onClick={ gotoCurrent }>{ dateRangeTypeString }</a>
							<a href="#" className="wc-bookings-availability-navigation-next" onClick={ gotoNext }>{ <IconTrendingFlat /> }</a>
							{ /* eslint-enable jsx-a11y/anchor-is-valid */ }
						</div>
					) : ( '' ) }
				</div>
				<AvailabilityState>
					<EventCalendar { ...attributes } />
				</AvailabilityState>
				{
					// Popover causes problems with the block picker when the example is rendered.
					attributes.example ? (
						''
					) : <Popover.Slot />
				}
			</div>
		</SlotFillProvider>
	);
};

CalendarContainer.propTypes = {
	dateRangeType: PropTypes.string,
};

export default CalendarContainer;
