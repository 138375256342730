/**
 * External dependencies
 */
import PropTypes from 'prop-types';

/**
 * External dependencies.
 */
import { useState } from '@wordpress/element';

/**
 * Internal dependencies.
 */
import CommonContext from './context';

const CommonState = ( props ) => {
	let newProps;

	if ( 'undefined' !== props.isFrontend && true === props.isFrontend ) {
		newProps = props;
	} else {
		newProps = props.attributes;
	}

	/**
	 * Offset.
	 */
	const [ offset, setOffset ] = useState( 0 );

	const gotoPrev = ( e ) => {
		e.preventDefault();
		setOffset( parseInt( offset, 10 ) - 1 );
	};

	const gotoNext = ( e ) => {
		e.preventDefault();
		setOffset( parseInt( offset, 10 ) + 1 );
	};

	const gotoCurrent = ( e ) => {
		e.preventDefault();
		setOffset( 0 );
	};

	const resetOffset = () => {
		setOffset( 0 );
	};

	/**
	 * showDateNav.
	 */
	const onShowDateNavUpdate = () => {
		props.setAttributes( { showDateNav: ! newProps.showDateNav } );
	};

	/**
	 * showEmptyDates.
	 */
	const onShowEmptyDatesUpdate = () => {
		props.setAttributes( { showEmptyDates: ! newProps.showEmptyDates } );
	};

	return (
		<CommonContext.Provider value={ {
			offset: offset,
			gotoPrev: gotoPrev,
			gotoNext: gotoNext,
			gotoCurrent: gotoCurrent,
			resetOffset: resetOffset,
			showDateNav: newProps.showDateNav,
			onShowDateNavUpdate: onShowDateNavUpdate,
			showEmptyDates: newProps.showEmptyDates,
			onShowEmptyDatesUpdate: onShowEmptyDatesUpdate,
		} }
		>
			{ props.children }
		</CommonContext.Provider>
	);
};

CommonState.propTypes = {
	children: PropTypes.object,
	attributes: PropTypes.object,
	setAttributes: PropTypes.func,
	isFrontend: PropTypes.bool,
};

export default CommonState;
