/**
 * External dependencies
 */
import { __, _n, sprintf } from '@wordpress/i18n';
import { Component, createRef } from '@wordpress/element';
import { PropTypes } from 'prop-types';

/**
 * Internal dependencies
 */
import './style.scss';

class PageLoader extends Component {
	constructor() {
		super( ...arguments );
		this.onScroll = this.onScroll.bind( this );
		this.scrollTriggerRef = createRef();
	}

	componentDidMount() {
		window.addEventListener( 'scroll', this.onScroll, true );
	}

	componentDidUpdate( prevProps ) {
		if ( ( true === prevProps.isLoading ) && ( false === this.props.isLoading ) ) {
			// Check if we should ask for the next page. Reuse onScroll utility function.
			this.onScroll();
		}
		if ( ( false === prevProps.infiniteScrollEnabled ) && ( true === this.props.infiniteScrollEnabled ) ) {
			// Merchant has enabled inf scroll. Check if we should load more data.
			this.onScroll();
		}
	}

	componentWillUnmount() {
		window.removeEventListener( 'scroll', this.onScroll, true );
	}

	onScroll() {
		const { isLoading, loadMore } = this.props;

		if ( ! isLoading && this.scrollTriggerIsInViewport() ) {
			loadMore();
		}
	}

	/*
	 * Determine if scroll trigger div is currently scrolled into viewport.
	 */
	scrollTriggerIsInViewport() {
		const scrollTrigger = this.scrollTriggerRef.current;
		if ( null === scrollTrigger ) {
			return false;
		}
		const top = scrollTrigger.getBoundingClientRect().top;
		return top >= 0 && top <= window.innerHeight;
	}

	loadMoreClick = ( e ) => {
		e.preventDefault();
		const { loadMore } = this.props;
		loadMore();
	}

	render() {
		const {
			remainingRecords,
			infiniteScrollEnabled,
		} = this.props;

		if ( infiniteScrollEnabled ) {
			// When div is in viewport, next page will be loaded.
			return <div className="wc-bookings-availability-scroll-trigger" ref={ this.scrollTriggerRef } />;
		}

		return (
			<div className="wc-bookings-availability-load-more-listings">
				{ sprintf( _n( '%s additional listing.', '%s additional listings.', remainingRecords, 'woocommerce-bookings-availability' ), remainingRecords ) }
				&nbsp;
				<a onClick={ this.loadMoreClick } href="#"> { /* eslint-disable-line jsx-a11y/anchor-is-valid */ }
					{ __( 'Load more...', 'woocommerce-bookings-availability' ) }
				</a>
			</div>
		);
	}
}

PageLoader.propTypes = {
	/**
	 * Number of remaining records that are unloaded.
	 */
	remainingRecords: PropTypes.number.isRequired,
	/**
	 * Prop to enable infinite scroll.
	 */
	infiniteScrollEnabled: PropTypes.bool.isRequired,
	/**
	 * Callback function to load more records.
	 */
	loadMore: PropTypes.func.isRequired,

	isLoading: PropTypes.bool,
};

PageLoader.defaultProps = {
	/**
	 * Optional value that indicates that the external API fetch is already ongoing.
	 */
	isLoading: false,
};

export default PageLoader;
