/**
 * External dependencies
 */
import { Popover } from '@wordpress/components';
import { RawHTML } from '@wordpress/element';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { sprintf, __, _n } from '@wordpress/i18n';

/**
 * Internal dependencies
 */
import BookButton from '../book-button';
import { convertDate, formatDuration, CONVERT_DIRECTIONS } from '../../includes/date-utils';

const EventCalendarItemPopover = ( props ) => {
	const renderEventTime = ( product, availability ) => {
		if ( 1 < availability.length ) {
			return __( 'Multiple times available', 'woocommerce-bookings-availability' );
		} else if ( 1 === availability.length ) {
			const { date } = availability[ 0 ];
			return formatDuration( product, date );
		}
	};

	const {
		date,
		productId,
		productName,
		product,
		availability,
		price,
		hasPersons,
		hasResources,
		permalink,
		durationType,
		isPreview,
	} = props;
	const formattedDate = moment( date ).format( 'dddd, MMMM Do' );
	const bookButtonProps = {
		availability,
		hasPersons,
		hasResources,
		productId,
		date: availability.length ? ( availability[ 0 ].date || date ) : date,
		permalink,
		durationType,
		isPreview,
	};

	// Convert current date from local to server
	const currentDate = convertDate( moment(), CONVERT_DIRECTIONS.LOCAL_TO_SERVER );
	const isNotPast = ( a ) => {
		return 'day' === product.durationUnit ? moment.tz( a.date, wc_bookings_availability_args.server_timezone ).isSameOrAfter( currentDate, 'day' ) : moment.tz( a.date, wc_bookings_availability_args.server_timezone ).isAfter( currentDate );
	};

	// The API returns the date in UTC
	const availableSlots = availability
		.filter( ( a ) => a.available && isNotPast( a ) )
		.reduce( ( sum, slot ) => sum + slot.available, 0 );

	return (
		<Popover
			className="wc-bookings-availability-popover"
			position={ 'bottom center' }
			focusOnMount={ 'false' }
		>
			<table
				className="wc-bookings-availability-popover-container"
			>
				<thead>
					<tr>
						<th colSpan="2" className="wc-bookings-availability-popover-container-title">
							<h4>{ productName }</h4>
							{ /* translators: $d number of available slots */ }
							<em>{ sprintf( _n( `%d slot available`, `%d slots available`, availableSlots, 'woocommerce-bookings-availability' ), availableSlots ) }</em>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className="wc-bookings-availability-popover-container-label"><strong>{ __( 'Date', 'woocommerce-bookings-availability' ) }</strong></td>
						<td className="wc-bookings-availability-popover-container-value">{ formattedDate }</td>
					</tr>
					<tr>
						<td className="wc-bookings-availability-popover-container-label"><strong>{ __( 'Time', 'woocommerce-bookings-availability' ) }</strong></td>
						<td className="wc-bookings-availability-popover-container-value">{ renderEventTime( product, availability ) }</td>
					</tr>
					<tr>
						<td className="wc-bookings-availability-popover-container-label"><strong>{ __( 'Price', 'woocommerce-bookings-availability' ) }</strong></td>
						<td className="wc-bookings-availability-popover-container-value"><RawHTML>{ price }</RawHTML></td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<th colSpan="2" className="wc-bookings-availability-popover-container-button">
							<BookButton { ...bookButtonProps } />
						</th>
					</tr>
				</tfoot>
			</table>
		</Popover>
	);
};

EventCalendarItemPopover.propTypes = {
	/**
	 * Date of the event
	 */
	date: PropTypes.object.isRequired,
	/**
	 * Id of the product
	 */
	productId: PropTypes.string.isRequired,
	/**
	 * Name of the product
	 */
	productName: PropTypes.string.isRequired,
	/**
	 * Number of events available
	 */
	availability: PropTypes.array.isRequired,
	/**
	 * Product information.
	 */
	product: PropTypes.object,

	durationType: PropTypes.string,
	/**
	 * Product's price
	 */
	price: PropTypes.string.isRequired,
	/**
	 * Whether or not this booking has persons
	 */
	hasPersons: PropTypes.bool,
	/**
	 * Whether or not this booking has resources
	 */
	hasResources: PropTypes.bool,
	/**
	 * Products permalink
	 */
	permalink: PropTypes.string.isRequired,

	isPreview: PropTypes.bool,
};

export default EventCalendarItemPopover;
