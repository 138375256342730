/**
 * External dependencies
 */
import { render } from '@wordpress/element';
import './style.scss';

/**
 * Internal dependencies
 */
import CalendarContainer from '../../components/event-calendar/container';
import { decodeAttributesFor } from '../../includes/misc-utils';
import ProductsState from '../products-context/products-state';
import CommonState from '../common-context/common-state';
import CalendarState from './context/calendar-state';

const bookingsContainersHTML = document.getElementsByClassName( 'wc-bookings-availability-main-calendar-container' );

for ( const bookingsContainerHTML of bookingsContainersHTML ) {
	const attributes = decodeAttributesFor( 'calendar', bookingsContainerHTML.dataset );
	render(
		<CommonState isFrontend={ true } { ...attributes }>
			<ProductsState>
				<CalendarState isFrontend={ true } { ...attributes }>
					<CalendarContainer { ...attributes } />
				</CalendarState>
			</ProductsState>
		</CommonState>,
		bookingsContainerHTML
	);
}
