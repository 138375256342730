/**
 * External dependencies
 */
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import CalendarContext from './context';

const CalendarState = ( props ) => {
	let newProps;

	if ( 'undefined' !== props.isFrontend && true === props.isFrontend ) {
		newProps = props;
	} else {
		newProps = props.attributes;
	}

	/**
	 * dateRangeType.
	 */
	const onTimeRangeUpdate = ( dateRangeType ) => {
		props.setAttributes( { dateRangeType } );
	};

	/**
	 * showSoldOut.
	 */
	const onShowSoldOutTimesUpdate = () => {
		props.setAttributes( { showSoldOut: ! newProps.showSoldOut } );
	};

	return (
		<CalendarContext.Provider value={ {
			dateRangeType: newProps.dateRangeType,
			onTimeRangeUpdate: onTimeRangeUpdate,
			onShowSoldOutTimesUpdate: onShowSoldOutTimesUpdate,
			showSoldOut: newProps.showSoldOut,
		} }
		>
			{ props.children }
		</CalendarContext.Provider>
	);
};

CalendarState.propTypes = {
	children: PropTypes.object,
	attributes: PropTypes.object,
	setAttributes: PropTypes.func,
	isFrontend: PropTypes.bool,
};

export default CalendarState;
