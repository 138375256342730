/**
 * External dependencies.
 */
import { __ } from '@wordpress/i18n';

/**
 * Internal dependencies
 */
import { DATE_RANGES } from './date-utils';

export const SCREEN = {
	ALL_PRODUCTS: 'all-products',
	PRODUCTS_SCREEN: 'products-screen',
	CATEGORIES_SCREEN: 'categories-screen',
	RESOURCES_SCREEN: 'resources-screen',
};

export const screenOptions = [
	{ value: SCREEN.ALL_PRODUCTS, label: __( 'All bookable products', 'woocommerce-bookings-availability' ) },
	{ value: SCREEN.PRODUCTS_SCREEN, label: __( 'Specific bookable products', 'woocommerce-bookings-availability' ) },
	{ value: SCREEN.CATEGORIES_SCREEN, label: __( 'Specific categories', 'woocommerce-bookings-availability' ) },
	{ value: SCREEN.RESOURCES_SCREEN, label: __( 'Specific resources', 'woocommerce-bookings-availability' ) },
];

export const DEFAULT_PAGE_SIZE = 8;

/*
 * Global list of all attributes for both blocks.
 * Attributes are organized into 'common', 'calendar',
 * and 'schedule' props. 'common' attributes
 * apply to both blocks while 'calendar' and 'schedule'
 * apply to the specific block types only.
 *
 */
const attributesDictionary = {
	common: {
		categoryIds: {
			type: 'string',
			default: '',
			source: 'attribute',
			selector: '.wc-bookings-availability-main-container',
			attribute: 'data-category-ids',
		},
		currentScreen: {
			type: 'string',
			default: '',
		},
		productIds: {
			type: 'string',
			default: '',
			source: 'attribute',
			selector: '.wc-bookings-availability-main-container',
			attribute: 'data-product-ids',
		},
		resourceIds: {
			type: 'string',
			default: '',
			source: 'attribute',
			selector: '.wc-bookings-availability-main-container',
			attribute: 'data-resource-ids',
		},
		showDateNav: {
			type: 'boolean',
			default: true,
			attribute: 'data-show-date-nav',
		},
	},
	calendar: {
		dateRangeType: {
			type: 'string',
			default: DATE_RANGES.THIS_MONTH,
			attribute: 'data-date-range-type',
		},
		showSoldOut: {
			type: 'boolean',
			default: true,
			attribute: 'data-show-sold-out',
		},
		example: {
			type: 'boolean',
			default: false,
		},
	},
	schedule: {
		dateRangeType: {
			type: 'string',
			default: DATE_RANGES.TODAY,
			attribute: 'data-date-range-type',
		},
		infiniteScrollEnabled: {
			type: 'boolean',
			default: true,
			attribute: 'data-infinite-scroll-enabled',
		},
		maxDate: {
			type: 'date',
			default: null,
			source: 'attribute',
			selector: '.wc-bookings-availability-container',
			attribute: 'data-max-date',
		},
		minDate: {
			type: 'date',
			default: null,
			source: 'attribute',
			selector: '.wc-bookings-availability-container',
			attribute: 'data-min-date',
		},
		slotsPerPage: {
			type: 'number',
			default: DEFAULT_PAGE_SIZE,
			attribute: 'data-slots-per-page',
		},
		showEmptyDates: {
			type: 'boolean',
			default: true,
			attribute: 'data-show-empty-dates',
		},
	},
};

/**
 * Get attribute definitions for the given block type name.
 *
 * @param {string} blockName Name of block to retrieve from (e.g. 'calendar' or 'schedule')
 *
 * @return {object} Attribute defintions for block.
 */
export function getAttributesDefFor( blockName ) {
	// Merge common attributes with the specific block attributes.
	return { ...attributesDictionary.common, ...attributesDictionary[ blockName ] };
}

/**
 * Decode fontend data attributes for block props
 *
 * @param {string} blockName Name of block to retrieve from (e.g. 'calendar' or 'schedule')
 * @param {object} attributes Object data properties pulled directly from DOM.
 *
 * @return {string} Decoded attributes.
 */
export function decodeAttributesFor( blockName, attributes ) {
	const attributesDef = getAttributesDefFor( blockName );
	const decodedAttributes = {};
	for ( const key of Object.keys( attributesDef ) ) {
		const type = attributesDef[ key ].type;
		// Fill in missing values with defaults
		const value = attributes.hasOwnProperty( key ) ? attributes[ key ] : attributesDef[ key ].default;

		switch ( type ) {
			case 'boolean':
				decodedAttributes[ key ] = ( 'true' === value || true === value ) ? true : false;
				break;
			case 'number':
				decodedAttributes[ key ] = parseInt( value );
				break;
			default:
				decodedAttributes[ key ] = value;
		}
	}
	return decodedAttributes;
}
